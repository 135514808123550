import React from "react";
import RichTextView from "./RichText.view";
import UseComponentVisibility from "@/hooks/useComponentVisibility";

const RichText = ({
  heading,
  subHeading,
  content,
  showOn,
  headingAlignment = "justify",
  codeLanguage = "cpp",
}) => {
  return (
    <UseComponentVisibility conditions={showOn}>
      <section data-outscal-section className="txt-content">
        <RichTextView
          heading={heading}
          subHeading={subHeading}
          content={content}
          headingAlignment={headingAlignment}
          codeLanguage={codeLanguage}
        />
      </section>
    </UseComponentVisibility>
  );
};

export default RichText;
