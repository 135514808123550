import Link from "next/link";
import { styled } from "styled-components";
import { Div } from "@/outscal-commons-frontend/Styled/LayoutUtils";
import { Text, H2 } from "@/outscal-commons-frontend/Styled/TextStyles";

export const Root = styled.section`
  display: flex;
  flex-direction: column;
  align-items: ${(p) => p.alignment};
  text-align: ${(p) => p.alignment};
  justify-content: center;
  gap: 12px;
  @media (min-width: ${(p) => p.theme.breakpoints.sm}px) {
    border-radius: ${(p) => p.theme.borderRadius.sm};
  }
`;

export const Title = styled(H2)`
  text-align: ${(p) => p.headingAlignment};
`;

export const SubHeading = styled(Text)`
  text-align: ${(p) => p.headingAlignment};
`;

export const BodyDiv = styled(Div)`
  text-align: ${(p) => p.headingAlignment};
`;

export const StyledLink = styled(Link)`
  font-weight: ${(p) => p.theme.fontWeight.medium};
  font-size: ${(p) => p.theme.fontSize.sm};
  &:hover {
    color: ${(p) => p.theme.colors.themeBlue};
  }
`;
