import React from "react";
import Image from "next/image";
import { CopyBlock } from "react-code-blocks";
import { BlocksRenderer } from "@strapi/blocks-react-renderer";
import { Theme } from "@/outscal-commons-frontend/Styled/Theme";
import { H1, H2, H3, Text } from "@/outscal-commons-frontend/Styled/TextStyles";
import { Root, SubHeading, Title, StyledLink } from "./RichText.styles";
import { Div } from "@/outscal-commons-frontend/Styled";

const RichTextView = ({
  heading,
  subHeading,
  content,
  headingAlignment,
  codeLanguage,
}) => {
  return (
    <Root alignment={headingAlignment}>
      {heading && (
        <Title
          headingAlignment={headingAlignment}
          fontSize="xxxl"
          fontWeight="midBold"
        >
          {heading}
        </Title>
      )}
      {subHeading && (
        <SubHeading
          headingAlignment={headingAlignment}
          color="textDarkGrey"
          fontSize="sm"
        >
          {subHeading}
        </SubHeading>
      )}
      <BlocksRenderer
        content={content}
        blocks={{
          heading: ({ level, children }) => {
            if (level === 1) {
              return <H1>{children}</H1>;
            }
            if (level === 2) {
              return <H2>{children}</H2>;
            }
            if (level === 3) {
              return <H3 fontSize="sm">{children}</H3>;
            }
          },
          image: ({ image }) => {
            return (
              <Image
                src={image.url}
                width={image.width}
                height={image.height}
                alt={image.alternativeText || ""}
              />
            );
          },
          paragraph: ({ children }) => {
            return (
              <Text color="textDarkGrey" fontSize="sm">
                {children}
              </Text>
            );
          },
          list: ({ format, children }) => {
            if (format === "ordered") {
              return (
                <ol
                  style={{
                    color: Theme.colors.textDarkGrey,
                    fontSize: Theme.fontSize.sm,
                  }}
                >
                  {children}
                </ol>
              );
            } else {
              return (
                <ul
                  style={{
                    color: Theme.colors.textDarkGrey,
                    fontSize: Theme.fontSize.sm,
                  }}
                >
                  {children}
                </ul>
              );
            }
          },
          link: ({ children, url }) => (
            <StyledLink href={url}>{children}</StyledLink>
          ),
          code: ({ children }) => (
            <Div style={{ fontSize: Theme.fontSize.sm }}>
              <CopyBlock
                text={children[0]?.props?.text}
                language={codeLanguage}
                showLineNumbers={true}
                codeBlock={true}
                theme="obsidian"
              />
            </Div>
          ),
        }}
      />
    </Root>
  );
};

export default RichTextView;
